import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
<path d="M5140 8048 c-50 -111 -7 -240 104 -313 32 -21 132 -30 184 -16 41 11
112 63 112 82 0 9 -27 42 -83 100 -15 16 -50 45 -79 65 -29 21 -61 46 -73 56
-24 24 -80 53 -118 62 -25 6 -29 3 -47 -36z"/>
<path d="M4193 7723 l-41 -42 24 -15 c13 -9 31 -30 40 -48 l16 -32 13 31 c8
18 25 39 40 49 l26 17 -22 15 c-11 8 -28 26 -37 41 l-17 26 -42 -42z"/>
<path d="M6448 7719 c-36 -36 -36 -37 -14 -47 12 -5 30 -25 39 -43 9 -19 17
-29 17 -22 0 6 15 27 33 45 l32 33 -35 35 -35 35 -37 -36z"/>
<path d="M5328 7570 c-56 -122 -487 -980 -505 -1007 -57 -84 -86 -183 -54
-183 11 0 114 -141 107 -147 -2 -3 -15 8 -28 23 -64 77 -113 125 -134 130 -13
3 -22 11 -21 17 1 6 -9 16 -22 23 -20 10 -28 10 -47 -3 -14 -9 -21 -20 -17
-30 5 -13 2 -13 -24 1 -17 8 -44 26 -59 39 -16 13 -49 35 -74 48 -25 13 -49
29 -55 34 -5 6 -37 28 -70 50 -33 22 -103 72 -156 112 -53 39 -116 85 -140
100 -58 37 -180 124 -197 140 -13 12 -42 18 -42 9 0 -3 11 -31 25 -63 37 -88
332 -933 372 -1068 23 -77 30 -81 105 -62 35 9 101 23 148 32 47 9 117 23 155
31 39 8 86 14 105 14 19 0 73 6 120 14 47 8 177 19 290 26 113 6 216 12 230
13 l25 2 -3 238 c-4 255 -9 287 -58 336 -13 13 -24 32 -24 41 0 25 -27 33 -39
12 -5 -9 -12 -14 -15 -11 -3 3 27 68 66 145 l72 139 2 360 c2 198 -1 392 -5
430 l-8 70 -25 -55z m-137 -1163 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0
-11 -7 -18z m-514 -3 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-29 -35 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5
13 -11z"/>
<path d="M4458 7470 c-10 -23 -29 -47 -47 -58 -38 -21 -38 -26 -1 -42 19 -8
36 -26 47 -51 20 -47 29 -49 38 -8 6 27 29 48 80 74 6 3 5 7 -3 11 -32 15 -71
57 -77 84 -9 40 -17 38 -37 -10z"/>
<path d="M6219 7467 c-8 -18 -28 -41 -46 -52 -18 -11 -32 -22 -33 -25 0 -3 13
-11 30 -18 18 -8 36 -28 47 -53 l18 -41 17 41 c11 25 28 46 50 57 30 16 31 17
12 25 -24 8 -64 55 -64 75 0 27 -17 22 -31 -9z"/>
<path d="M4000 7440 c-13 -25 -4 -60 16 -60 19 0 28 24 20 55 -8 30 -22 32
-36 5z"/>
<path d="M4870 7446 c0 -9 -9 -25 -20 -36 -21 -21 -26 -40 -11 -40 5 0 14 -14
21 -31 l13 -30 15 30 c8 17 21 31 28 31 20 0 17 13 -6 33 -11 9 -20 23 -20 31
0 8 -4 18 -10 21 -5 3 -10 -1 -10 -9z"/>
<path d="M5833 7430 c-17 -26 -16 -30 3 -30 8 0 12 -5 9 -10 -5 -8 -11 -7 -21
1 -11 9 -18 9 -26 1 -9 -9 -9 -12 0 -12 6 0 20 -10 32 -22 l20 -22 0 21 c0 12
6 23 13 26 9 3 9 7 1 18 -7 8 -13 22 -15 32 -4 16 -5 16 -16 -3z"/>
<path d="M6681 7431 c-7 -13 -6 -23 2 -36 12 -15 14 -15 26 -1 13 16 7 56 -9
56 -5 0 -14 -8 -19 -19z"/>
<path d="M3459 7284 c-8 -10 -15 -48 -17 -90 -4 -61 -1 -79 18 -111 31 -53 76
-88 136 -106 47 -14 55 -14 105 3 39 13 65 31 91 62 37 42 51 72 25 49 -11 -8
-28 2 -78 49 -35 33 -69 60 -75 60 -6 0 -29 16 -50 36 -64 59 -129 79 -155 48z"/>
<path d="M6883 7288 c-16 -21 -33 -79 -33 -112 1 -73 78 -172 154 -196 74 -24
170 10 215 76 25 36 23 56 -4 34 -12 -10 -15 -10 -15 2 0 18 -53 68 -109 103
-25 15 -54 36 -65 47 -28 26 -90 58 -114 58 -11 0 -24 -6 -29 -12z"/>
<path d="M4146 7104 c-3 -9 -6 -33 -6 -54 0 -74 94 -139 156 -108 37 18 52 43
38 66 -6 9 -37 38 -69 65 -61 49 -107 61 -119 31z"/>
<path d="M6336 7089 c-9 -45 2 -80 37 -116 23 -24 40 -33 63 -33 44 0 82 18
90 44 10 30 -108 136 -152 136 -27 0 -32 -4 -38 -31z"/>
<path d="M4686 7005 c-6 -17 17 -31 34 -20 5 3 10 13 10 21 0 19 -36 18 -44
-1z"/>
<path d="M5995 7010 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
<path d="M6875 6904 c-40 -21 -43 -25 -14 -22 24 3 55 25 47 33 -2 2 -17 -3
-33 -11z"/>
<path d="M4329 6895 c-2 -20 2 -63 18 -165 14 -94 19 -108 46 -132 16 -16 36
-28 43 -28 8 0 14 -4 14 -8 0 -5 21 -19 46 -31 25 -13 48 -29 51 -37 9 -23 25
-4 19 22 -7 26 -199 350 -222 374 -8 8 -14 10 -15 5z"/>
<path d="M6263 6841 c-77 -53 -286 -203 -327 -236 -19 -15 -19 -16 5 -67 13
-29 32 -67 43 -83 l19 -31 57 30 c55 29 93 70 134 146 10 19 30 55 43 80 14
25 41 74 61 110 19 36 39 70 44 77 28 35 -6 24 -79 -26z"/>
<path d="M6830 6752 c-13 -26 -35 -61 -49 -78 -54 -64 -61 -74 -61 -84 0 -6
-4 -10 -9 -10 -5 0 -12 -8 -15 -17 -4 -10 -19 -32 -34 -50 -15 -18 -43 -55
-62 -83 -40 -58 -205 -287 -223 -310 -7 -9 -32 -41 -56 -73 -47 -62 -67 -70
-77 -32 -5 19 -13 25 -35 25 -16 0 -29 -4 -29 -10 0 -5 -4 -10 -10 -10 -5 0
-10 6 -10 13 0 8 -19 12 -63 11 -80 -1 -104 -10 -122 -44 -8 -15 -31 -36 -52
-47 -21 -11 -43 -30 -49 -42 -7 -13 -17 -28 -23 -34 -20 -20 -12 -37 17 -37
39 0 229 -29 272 -41 19 -5 60 -14 90 -19 30 -5 97 -18 149 -29 116 -26 131
-26 131 -3 0 9 18 69 41 132 22 63 49 142 59 175 37 120 199 602 224 669 31
81 29 96 -4 28z"/>
<path d="M3155 6350 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3772 6348 c-8 -8 -9 -15 -1 -25 22 -26 55 -10 43 21 -7 19 -25 21
-42 4z"/>
<path d="M6910 6330 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3341 6196 c-11 -25 -28 -46 -38 -49 -15 -4 -15 -5 2 -6 11 0 29 -7
40 -14 17 -11 25 -11 55 4 19 10 30 18 24 19 -6 0 -19 20 -28 45 -10 25 -21
45 -26 45 -4 0 -17 -20 -29 -44z"/>
<path d="M7343 6228 c-6 -7 -18 -29 -26 -50 -14 -33 -14 -37 1 -42 10 -3 22
-8 27 -11 6 -2 18 2 28 9 17 13 17 16 -1 61 -14 36 -21 44 -29 33z"/>
<path d="M6260 6040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3358 5944 c-5 -4 -8 -17 -8 -28 -1 -35 -36 -102 -72 -136 -39 -38
-86 -63 -148 -81 l-45 -12 52 -13 c67 -15 135 -55 164 -95 12 -17 32 -63 45
-103 20 -62 23 -67 24 -36 4 110 80 207 185 234 l46 11 -55 19 c-102 35 -162
105 -175 204 -3 24 -9 40 -13 36z"/>
<path d="M2824 5896 c-3 -13 -1 -33 5 -44 6 -12 11 -41 11 -67 0 -37 -5 -51
-26 -70 l-26 -24 26 -34 c29 -38 34 -89 14 -144 -14 -37 -4 -66 25 -71 29 -6
37 30 20 87 -17 60 -13 88 22 129 l24 29 -24 21 c-35 30 -41 66 -21 127 19 61
13 85 -20 85 -17 0 -25 -7 -30 -24z"/>
<path d="M7351 5904 c-3 -68 -91 -175 -161 -194 -54 -15 -56 -24 -7 -38 81
-22 156 -122 157 -210 0 -54 16 -47 24 12 9 55 58 131 105 161 21 12 59 29 86
37 l50 15 -59 19 c-83 26 -151 90 -176 164 -10 30 -19 45 -19 34z"/>
<path d="M7852 5908 c-16 -16 -15 -30 2 -63 l14 -27 7 42 c8 45 -3 68 -23 48z"/>
<path d="M7837 5723 c-23 -31 -25 -38 -13 -52 7 -9 21 -29 29 -46 14 -26 16
-27 17 -9 0 12 9 33 20 47 13 16 17 28 10 32 -6 4 -16 19 -23 35 l-12 28 -28
-35z"/>
<path d="M3778 5709 c-23 -13 -23 -32 1 -53 25 -23 61 -6 61 28 0 33 -29 44
-62 25z"/>
<path d="M6890 5705 c-19 -23 -8 -50 22 -53 39 -5 57 31 26 52 -28 20 -32 20
-48 1z"/>
<path d="M4750 5670 c-210 -27 -510 -92 -538 -116 -8 -6 111 -164 124 -164 8
0 79 11 157 24 78 13 201 29 272 35 72 7 191 18 265 26 74 9 178 15 230 15 52
0 101 4 108 8 9 6 10 31 6 100 l-7 92 -241 -1 c-165 -1 -283 -7 -376 -19z"/>
<path d="M6100 5640 c-6 -4 -20 -40 -31 -81 -12 -41 -26 -80 -31 -86 -14 -18
14 -27 172 -56 74 -14 143 -28 152 -32 19 -8 42 13 113 99 58 71 60 75 32 85
-21 8 -179 40 -349 70 -26 4 -52 5 -58 1z"/>
<path d="M7849 5533 c-14 -44 -15 -55 -4 -70 11 -14 16 -15 26 -6 8 8 9 28 3
69 l-9 58 -16 -51z"/>
<path d="M5065 5323 c-208 -10 -412 -25 -485 -37 -82 -14 -207 -43 -222 -52
-6 -4 7 -49 43 -145 24 -63 219 -79 954 -79 579 0 806 11 914 42 41 12 45 17
72 83 37 92 37 103 -3 110 -79 14 -155 20 -183 15 -29 -5 -30 -6 -31 -68 -1
-34 -4 -65 -7 -68 -7 -7 -48 -7 -447 9 l-295 12 -3 86 -3 86 -46 7 c-44 6
-127 6 -258 -1z"/>
<path d="M3350 5278 c-12 -15 -33 -23 -80 -28 -36 -4 -53 -8 -39 -9 73 -3 91
-13 111 -59 22 -49 34 -46 54 11 10 31 13 32 77 38 91 8 96 19 9 19 -62 0 -74
3 -94 24 l-23 24 -15 -20z"/>
<path d="M6730 5268 c-10 -21 -10 -30 3 -48 15 -22 15 -22 26 -2 14 27 14 34
-3 57 -14 19 -15 19 -26 -7z"/>
<path d="M7325 5270 c-10 -11 -30 -20 -44 -21 l-26 -1 27 -11 c17 -7 33 -25
43 -49 18 -46 30 -48 44 -6 6 17 19 40 28 50 15 17 15 18 0 18 -9 0 -22 9 -29
20 -7 11 -16 20 -19 20 -3 0 -14 -9 -24 -20z"/>
<path d="M3970 5244 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M3651 5018 c-10 -26 -37 -52 -63 -61 -26 -9 -22 -20 13 -36 19 -8 37
-27 46 -48 15 -37 19 -37 37 -1 6 13 26 32 43 43 18 11 32 22 33 25 0 3 -14
13 -31 23 -17 9 -36 31 -42 47 -14 36 -24 38 -36 8z"/>
<path d="M7033 5010 c-6 -16 -25 -38 -42 -47 -38 -22 -38 -26 -2 -39 16 -7 34
-24 42 -43 17 -38 26 -39 39 -6 5 14 23 34 40 43 36 21 37 26 4 40 -14 7 -32
28 -40 47 -18 43 -26 44 -41 5z"/>
<path d="M3132 4949 c-15 -24 -15 -26 2 -32 22 -9 37 15 22 35 -11 15 -13 15
-24 -3z"/>
<path d="M7561 4948 c-17 -27 2 -49 23 -28 12 12 12 18 2 32 -11 15 -13 15
-25 -4z"/>
<path d="M3663 4655 c-29 -18 -49 -41 -67 -77 -24 -48 -26 -61 -26 -192 0 -78
5 -159 11 -181 24 -85 120 -144 215 -129 85 12 141 71 146 154 l3 45 -46 3
-47 3 -7 -38 c-12 -67 -29 -84 -82 -81 -37 2 -50 9 -67 31 -18 25 -21 45 -24
160 -4 148 5 196 45 226 52 42 116 12 128 -59 l7 -40 50 0 50 0 -7 43 c-17
103 -80 157 -181 157 -45 0 -69 -6 -101 -25z"/>
<path d="M4574 4666 c-42 -19 -81 -62 -100 -112 -13 -33 -15 -73 -12 -197 3
-154 3 -156 33 -198 44 -62 83 -82 166 -83 60 0 73 3 110 29 24 17 52 50 65
75 21 41 23 58 23 195 1 125 -3 157 -18 190 -22 47 -64 89 -104 104 -39 15
-126 13 -163 -3z m145 -90 c31 -24 41 -75 41 -201 0 -172 -20 -215 -100 -215
-80 0 -100 42 -100 217 0 141 13 187 60 210 27 14 76 8 99 -11z"/>
<path d="M1680 4664 c0 -3 23 -80 51 -172 61 -200 119 -399 119 -407 0 -3 22
-5 50 -5 l49 0 22 73 c12 39 47 158 79 262 32 105 61 205 65 223 l7 33 -53 -3
-53 -3 -57 -215 c-31 -118 -59 -208 -62 -200 -3 8 -8 26 -11 40 -4 14 -26 102
-51 197 -25 94 -45 174 -45 177 0 3 -25 6 -55 6 -30 0 -55 -2 -55 -6z"/>
<path d="M2215 4523 c-25 -82 -56 -182 -70 -223 -13 -41 -31 -101 -39 -132 -9
-32 -19 -64 -22 -73 -5 -12 3 -15 48 -15 l54 0 18 70 18 70 84 0 c59 0 86 -4
88 -12 2 -7 11 -39 20 -70 l17 -58 50 0 c27 0 49 4 49 9 0 10 -54 195 -80 271
-10 30 -35 112 -56 183 l-38 127 -47 0 -48 0 -46 -147z m124 -111 c13 -51 21
-96 18 -98 -3 -3 -27 -4 -54 -2 l-50 3 25 103 c14 56 28 99 32 95 3 -5 17 -50
29 -101z"/>
<path d="M2570 4376 l0 -296 165 0 165 0 0 40 0 40 -110 0 -110 0 -2 253 -3
252 -52 3 -53 3 0 -295z"/>
<path d="M2950 4375 l0 -295 50 0 50 0 0 295 0 295 -50 0 -50 0 0 -295z"/>
<path d="M3140 4374 l0 -296 119 4 c114 3 121 4 163 33 29 21 52 48 72 89 27
53 30 69 30 160 1 118 -14 179 -56 230 -51 62 -91 76 -218 76 l-110 0 0 -296z
m210 203 c14 -6 35 -32 48 -57 18 -37 22 -60 22 -145 0 -155 -38 -215 -137
-215 l-43 0 0 215 0 215 43 0 c23 0 53 -6 67 -13z"/>
<path d="M4010 4375 l0 -295 55 0 55 0 0 120 0 120 64 0 c83 0 96 -15 96 -111
0 -36 5 -80 11 -98 10 -30 13 -31 65 -31 54 0 68 12 43 37 -6 6 -14 52 -18
102 -6 71 -13 99 -30 122 l-22 29 23 30 c16 22 23 48 26 93 4 77 -18 122 -74
154 -35 20 -53 23 -166 23 l-128 0 0 -295z m243 197 c21 -23 31 -73 23 -106
-11 -43 -33 -56 -97 -56 l-59 0 0 90 0 90 58 0 c44 0 63 -5 75 -18z"/>
<path d="M4880 4667 c0 -8 111 -555 116 -569 4 -14 16 -18 49 -18 38 0 45 3
49 23 5 21 10 45 60 267 13 58 26 98 29 90 3 -8 16 -62 28 -120 12 -58 29
-139 38 -180 l17 -75 46 -3 46 -3 16 73 c8 40 34 165 56 278 23 113 43 213 46
223 5 15 -2 17 -48 17 l-53 0 -29 -183 c-16 -100 -33 -186 -37 -192 -5 -5 -9
-6 -9 -3 0 10 -40 200 -61 290 l-21 88 -43 0 c-42 0 -42 0 -49 -42 -8 -53 -65
-317 -73 -338 -4 -11 -56 259 -73 378 0 3 -100 3 -100 -1z"/>
<path d="M5520 4375 l0 -295 51 0 51 0 -4 205 c-2 113 -2 205 0 205 2 0 48
-92 103 -205 l100 -205 50 0 49 0 0 295 0 295 -50 0 -50 0 -1 -202 -1 -203
-99 203 -100 202 -49 0 -50 0 0 -295z"/>
<path d="M6133 4653 c3 -10 28 -94 56 -188 87 -288 100 -329 107 -357 6 -26
10 -28 54 -28 l48 0 16 48 c31 96 144 478 151 510 l7 33 -53 -3 -52 -3 -44
-165 c-24 -91 -48 -187 -54 -214 -7 -32 -14 -44 -19 -35 -4 8 -31 105 -60 217
l-54 202 -54 0 c-47 0 -54 -2 -49 -17z"/>
<path d="M6610 4375 l0 -295 173 2 172 3 3 38 3 37 -125 0 -126 0 0 90 0 90
105 0 105 0 0 45 0 45 -105 0 -105 0 0 80 0 80 120 0 120 0 0 40 0 40 -170 0
-170 0 0 -295z"/>
<path d="M7010 4375 l0 -295 51 0 52 0 -5 205 c-3 113 -3 205 -1 205 2 0 49
-92 103 -205 l99 -205 51 0 50 0 0 295 0 295 -50 0 -50 0 -2 -202 -3 -203 -36
75 c-20 41 -64 132 -99 203 l-61 127 -50 0 -49 0 0 -295z"/>
<path d="M7440 4630 l0 -40 75 0 75 0 0 -255 0 -255 50 0 50 0 0 255 0 255 75
0 75 0 0 40 0 40 -200 0 -200 0 0 -40z"/>
<path d="M7879 4666 c-2 -2 -2 -112 1 -243 l5 -240 28 -36 c15 -21 40 -43 55
-51 100 -51 231 -13 273 78 16 36 19 68 19 269 l0 227 -50 0 -50 0 0 -220 c0
-275 -5 -290 -99 -290 -26 0 -44 7 -57 21 -17 19 -19 41 -22 255 l-3 234 -48
0 c-26 0 -50 -2 -52 -4z"/>
<path d="M8330 4376 l0 -296 50 0 50 0 0 120 0 120 63 0 c88 0 99 -14 107
-128 3 -48 9 -93 13 -99 5 -8 29 -13 58 -13 41 0 49 3 49 18 0 10 -4 22 -9 28
-5 5 -11 48 -13 95 -3 68 -9 92 -26 117 l-22 30 26 49 c22 40 25 57 20 102 -5
58 -24 91 -70 125 -24 18 -45 21 -162 24 l-134 4 0 -296z m225 202 c38 -17 52
-78 28 -126 -18 -37 -19 -37 -85 -40 l-68 -3 0 91 0 90 50 0 c27 0 61 -5 75
-12z"/>
<path d="M8790 4375 l0 -295 175 0 175 0 0 40 0 39 -122 3 -123 3 -3 87 -3 87
108 3 108 3 0 40 0 40 -105 5 -105 5 0 75 0 75 123 3 122 3 0 39 0 40 -175 0
-175 0 0 -295z"/>
<path d="M3566 3918 c-6 -27 -32 -176 -41 -231 -5 -33 -4 -38 11 -35 15 2 22
27 40 133 13 72 23 136 23 143 2 22 -28 13 -33 -10z"/>
<path d="M3877 3933 c-4 -3 -10 -28 -13 -55 -5 -38 -9 -46 -20 -37 -23 19 -85
6 -107 -22 -27 -37 -34 -112 -13 -144 15 -22 22 -25 76 -23 33 1 60 4 60 7 0
3 9 58 20 121 11 63 20 125 20 138 0 21 -10 29 -23 15z m-42 -119 c15 -15 14
-48 -2 -107 -6 -23 -43 -37 -64 -26 -49 25 -17 147 38 149 6 0 19 -7 28 -16z"/>
<path d="M4147 3923 c-8 -10 -19 -30 -22 -45 -4 -16 -13 -28 -21 -28 -17 0
-19 -27 -3 -32 9 -3 9 -20 0 -69 -14 -85 -14 -99 3 -99 16 0 22 22 32 103 6
50 26 88 43 79 3 -2 7 1 9 7 2 6 -3 11 -12 11 -10 0 -16 9 -16 25 0 20 6 27
30 32 17 3 30 12 30 19 0 20 -54 18 -73 -3z"/>
<path d="M5202 3817 c-26 -144 -27 -167 -9 -167 11 0 39 127 61 274 0 6 -6 11
-15 13 -13 3 -20 -18 -37 -120z"/>
<path d="M5606 3932 c-10 -16 -49 -272 -43 -279 4 -3 13 0 21 6 10 8 16 9 21
1 8 -13 60 -13 85 0 27 14 54 92 46 134 -9 45 -27 58 -73 55 -41 -4 -39 -6
-31 60 3 24 -15 40 -26 23z m82 -114 c7 -7 12 -32 12 -55 0 -56 -36 -98 -72
-84 -21 8 -23 15 -21 62 2 41 8 58 24 72 25 20 40 22 57 5z"/>
<path d="M5805 3928 c-6 -19 -45 -245 -45 -263 0 -29 29 -15 35 18 26 143 41
248 37 252 -9 9 -23 5 -27 -7z"/>
<path d="M7825 3915 c-3 -14 -7 -36 -10 -49 -4 -21 -9 -23 -49 -19 -35 4 -48
1 -64 -15 -31 -31 -46 -95 -32 -138 13 -38 30 -45 99 -41 38 2 45 5 48 27 2
13 12 74 23 134 24 131 24 126 5 126 -8 0 -17 -11 -20 -25z m-35 -100 c15 -18
4 -109 -15 -125 -8 -6 -26 -10 -42 -8 -26 3 -28 7 -31 50 -3 40 1 52 22 73 29
28 48 32 66 10z"/>
<path d="M3652 3908 c7 -19 38 -22 38 -4 0 10 -9 16 -21 16 -12 0 -19 -5 -17
-12z"/>
<path d="M5310 3904 c0 -9 7 -14 17 -12 25 5 28 28 4 28 -12 0 -21 -6 -21 -16z"/>
<path d="M4630 3880 c0 -13 -10 -24 -26 -31 -25 -9 -26 -24 -1 -24 8 0 10 -12
7 -35 -2 -19 -4 -57 -5 -85 0 -47 2 -50 28 -53 19 -2 27 1 27 12 0 9 -7 16
-16 16 -13 0 -15 8 -9 53 9 66 21 98 36 95 6 -2 14 3 16 10 3 6 -2 12 -11 12
-10 0 -16 9 -16 25 0 16 -6 25 -15 25 -8 0 -15 -9 -15 -20z"/>
<path d="M7400 3875 c0 -18 -5 -25 -20 -25 -11 0 -20 -7 -20 -16 0 -8 4 -13 9
-9 18 10 21 -12 10 -70 -14 -75 -6 -105 26 -105 16 0 25 6 25 15 0 8 -7 15
-16 15 -12 0 -14 7 -10 28 3 15 8 46 11 69 5 29 12 43 26 46 23 6 25 27 3 27
-12 0 -15 7 -12 25 4 19 1 25 -13 25 -14 0 -19 -7 -19 -25z"/>
<path d="M3400 3849 c-40 -9 -72 -42 -45 -47 9 -2 24 3 34 12 24 22 48 20 61
-4 16 -29 4 -40 -41 -40 -29 0 -47 -7 -64 -25 -52 -51 -20 -95 66 -93 l56 1
12 74 c7 40 10 81 6 92 -8 26 -44 39 -85 30z m45 -109 c13 -21 -24 -60 -55
-60 -25 0 -30 4 -30 25 0 13 8 29 18 34 22 14 59 14 67 1z"/>
<path d="M4951 3851 c-7 -5 -21 -6 -31 -4 -15 4 -20 -8 -34 -84 -20 -107 -20
-113 -2 -113 10 0 18 20 26 58 6 31 13 67 16 80 3 15 14 24 35 29 20 4 29 11
27 21 -4 19 -19 24 -37 13z"/>
<path d="M3174 3818 c4 -18 12 -63 18 -100 8 -43 17 -68 25 -68 7 0 36 45 63
101 41 80 47 100 33 97 -10 -2 -32 -32 -52 -73 l-34 -70 -7 35 c-4 19 -11 52
-14 73 -5 24 -12 37 -23 37 -12 0 -14 -7 -9 -32z"/>
<path d="M3647 3843 c-3 -5 -10 -42 -17 -83 -6 -41 -14 -83 -16 -92 -4 -13 0
-18 13 -18 16 0 21 13 32 78 7 42 15 87 18 100 4 15 1 22 -10 22 -9 0 -18 -3
-20 -7z"/>
<path d="M4235 3830 c-26 -29 -11 -36 30 -14 32 17 36 17 51 2 26 -26 11 -48
-33 -48 -54 0 -83 -23 -83 -64 0 -46 16 -55 87 -53 l60 1 7 78 c8 101 0 118
-58 118 -30 0 -48 -6 -61 -20z m85 -99 c0 -29 -29 -53 -59 -49 -54 6 -27 68
30 68 22 0 29 -5 29 -19z"/>
<path d="M4430 3830 c-11 -11 -20 -27 -20 -35 0 -21 37 -52 71 -59 35 -7 40
-39 9 -56 -21 -11 -60 2 -60 20 0 13 -27 13 -35 0 -3 -5 3 -18 15 -30 30 -30
90 -27 121 6 22 23 22 27 9 51 -8 15 -31 32 -50 38 -24 8 -36 19 -38 33 -2 12
1 22 6 22 6 0 17 3 26 6 8 3 22 -3 33 -15 20 -24 43 -28 43 -8 0 45 -93 64
-130 27z"/>
<path d="M5032 3830 c-42 -39 -49 -122 -14 -162 27 -32 122 -18 122 17 0 19
-10 18 -34 0 -32 -24 -75 -3 -76 37 0 14 11 18 63 20 60 3 62 4 65 31 2 16 -4
39 -13 53 -20 31 -81 34 -113 4z m82 -16 c30 -29 20 -44 -29 -44 -49 0 -56 11
-28 42 20 22 37 23 57 2z"/>
<path d="M5300 3846 c0 -2 -7 -40 -15 -83 -19 -98 -19 -113 0 -113 8 0 15 1
15 3 0 1 7 40 15 87 19 105 19 110 0 110 -8 0 -15 -2 -15 -4z"/>
<path d="M5400 3832 c-35 -28 -16 -47 19 -21 36 26 71 18 71 -15 0 -21 -6 -24
-52 -28 -57 -6 -78 -24 -78 -69 0 -39 18 -48 87 -46 l62 1 6 83 c5 60 4 87 -5
98 -17 21 -83 19 -110 -3z m80 -105 c0 -31 -18 -47 -52 -47 -39 0 -48 27 -19
52 34 26 71 24 71 -5z"/>
<path d="M5892 3827 c-43 -45 -49 -119 -14 -159 27 -32 122 -18 122 17 0 17
-11 19 -25 6 -34 -31 -85 -13 -85 30 0 17 7 19 65 19 l65 0 0 38 c0 48 -24 72
-72 72 -23 0 -42 -8 -56 -23z m90 -22 c15 -33 9 -37 -48 -33 l-49 3 27 28 c34
34 55 34 70 2z"/>
<path d="M6236 3819 c-33 -39 -37 -126 -8 -152 28 -25 84 -22 110 6 39 41 21
51 -27 16 -23 -17 -32 -19 -50 -10 -32 17 -30 93 4 126 31 32 50 32 71 0 19
-30 34 -32 34 -6 0 31 -28 51 -70 51 -30 0 -43 -6 -64 -31z"/>
<path d="M6437 3839 c-23 -14 -47 -68 -47 -107 0 -16 9 -41 21 -56 45 -57 139
-19 154 64 7 37 -8 89 -28 102 -18 11 -79 9 -100 -3z m74 -19 c30 -16 27 -93
-6 -125 -28 -29 -34 -30 -60 -11 -35 25 -17 128 24 139 9 3 17 5 19 6 2 0 12
-4 23 -9z"/>
<path d="M6601 3771 c-6 -42 -14 -87 -18 -100 -5 -19 -2 -22 13 -19 14 2 21
19 32 79 11 64 17 78 38 87 45 21 57 6 50 -60 -3 -32 -9 -70 -12 -84 -6 -22
-4 -25 12 -22 14 2 21 19 31 76 8 47 9 82 3 98 -9 22 -14 24 -73 22 l-64 -1
-12 -76z"/>
<path d="M6816 3841 c-2 -3 -9 -40 -15 -81 -6 -41 -14 -83 -17 -92 -4 -13 0
-18 13 -18 16 0 21 13 32 75 13 80 38 113 76 99 15 -6 17 -16 11 -68 -3 -34
-9 -71 -12 -83 -5 -18 -2 -23 13 -23 16 0 21 12 32 79 18 109 10 122 -70 120
-33 -1 -61 -4 -63 -8z"/>
<path d="M7028 3833 c-43 -50 -50 -115 -17 -157 25 -32 86 -37 112 -8 23 26
10 34 -24 16 -32 -16 -62 -7 -73 22 -12 30 -6 34 58 34 46 0 65 4 69 14 8 20
-9 77 -25 88 -23 14 -84 9 -100 -9z m80 -15 c7 -7 12 -20 12 -30 0 -15 -8 -18
-45 -18 -49 0 -55 10 -25 40 23 23 41 25 58 8z"/>
<path d="M7211 3831 c-25 -25 -44 -94 -35 -124 18 -60 90 -79 132 -34 38 40
20 52 -25 17 -31 -24 -37 -25 -57 -4 -25 25 -21 90 9 119 30 31 40 31 69 0 28
-30 36 -31 36 -6 0 50 -90 71 -129 32z"/>
<path d="M7527 3839 c-54 -31 -62 -148 -13 -181 22 -15 83 -8 99 10 18 20 12
38 -7 23 -27 -21 -55 -22 -75 -2 -39 39 -28 51 43 51 75 0 87 14 62 74 -14 32
-19 36 -53 36 -21 0 -46 -5 -56 -11z m77 -31 c21 -29 12 -38 -40 -38 -48 0
-54 10 -24 40 26 26 45 25 64 -2z"/>
<path d="M2887 3684 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
15z"/>
<path d="M2977 3684 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
17z"/>
<path d="M3067 3676 c-7 -16 1 -26 20 -26 7 0 13 9 13 20 0 23 -25 27 -33 6z"/>
<path d="M3904 3673 c-3 -10 -11 -28 -17 -40 -6 -13 -7 -26 -3 -30 10 -11 35
19 45 55 8 26 7 32 -5 32 -8 0 -17 -8 -20 -17z"/>
<path d="M4692 3649 c-7 -23 -10 -44 -7 -48 10 -9 45 42 45 67 0 37 -24 25
-38 -19z"/>
<path d="M6022 3649 c-7 -23 -10 -44 -7 -47 10 -10 34 23 41 56 10 49 -18 41
-34 -9z"/>
<path d="M7867 3684 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
17z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
